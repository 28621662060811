import React, { Component, Fragment } from 'react';
import { connect } from 'dva';
import { Card, Icon, List, Switch, Modal } from 'antd';

// import styles from "./List.less";

import aliexpressLogo from '../../assets/email.jpg';

const { Meta } = Card;

@connect(({ emailTemplate, loading }) => ({
  emailTemplate,
  loading: loading.models.emailTemplate
}))
export default class EmailTemplateIndex extends Component {
  state = {
    switchStatus: false,
    bShowDetail: false
  };

  componentDidMount() {
    this.props.dispatch({
      type: 'emailTemplate/status',
      payload: {
        callback: data => {
          if (data.code === 1) {
            this.setState({
              switchStatus: data.data.status
            });
          }
        }
      }
    });
  }

  changeStatus = status => {
    this.props.dispatch({
      type: 'emailTemplate/update',
      payload: {}
    });
    this.setState({
      switchStatus: status
    });
  };

  render() {
    const { loading } = this.props;
    const { switchStatus, bShowDetail } = this.state;

    const list = [
      {
        id: 1,
        title: 'Default email template',
        name: 'email',
        src: aliexpressLogo
      }
    ];
    return (
      <Fragment>
        <List
          rowKey="id"
          grid={{ gutter: 24, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
          dataSource={list}
          loading={loading}
          hoverable
          onClick={this.showDetail}
          renderItem={item => (
            <List.Item>
              <Card cover={<img alt={item.name} src={item.src} height={200} />}>
                <Meta title={item.title} />
                <div style={{ marginTop: 12 }}>
                  <Switch
                    checkedChildren={<Icon type="check" />}
                    unCheckedChildren={<Icon type="cross" />}
                    checked={switchStatus}
                    onChange={this.changeStatus}
                    size="small"
                    style={{ marginRight: 10 }}
                  />
                  Send this email when tracking number is created
                </div>
              </Card>
            </List.Item>
          )}
        />
        {bShowDetail ? <Modal>d</Modal> : ''}
      </Fragment>
    );
  }
}
